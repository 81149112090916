body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDialog-container {
  align-items: flex-start !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 677px) {
  .MuiDialog-paper {
    min-width: 300px !important;
    border-radius: 0.75rem !important;
    max-width: 75% !important;
    height: 43% !important;
    margin: 15px !important;
  }
}

/* Small devices (portrait tablets and large phones, 678px and up) */
@media only screen and (min-width: 678px) {
  .MuiDialog-paper {
    min-width: 510px !important;
    border-radius: 0.75rem !important;
  }
}

.MuiDialogTitle-root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  border-bottom: 1px solid #e5e7eb !important;
  padding: 12px 16px !important;
  padding: 0.75rem 1rem !important;
}

.MuiButton-root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  /* border: 0.5px solid #e5e7eb !important; */
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
  /* padding: 0.75rem 1rem !important; */
  text-transform: capitalize !important;
}

.MuiBackdrop-root {
  background-color: rgba(107, 114, 128, 0.5) !important;
}

.MuiList-root {
  padding: 2px 0 0 0 !important;
}

.MuiMenuItem-root {
  font-size: 13px !important;
  color: #383838ee !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  border-bottom: 1px solid #88888842 !important;
  justify-content: flex-start;
  gap: 7px;
  padding-left: 13px !important;
  padding-right: 13px !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.animated-link {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

@keyframes shake {
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(3%);
  }
}
@keyframes line {
  0% {
    stroke-dashoffset: 22;
  }
  25% {
    stroke-dashoffset: 22;
  }
  50% {
    stroke-dashoffset: 0;
  }
  51% {
    stroke-dashoffset: 0;
  }
  80% {
    stroke-dashoffset: -22;
  }
  100% {
    stroke-dashoffset: -22;
  }
}
